<template>
  <div class="gift-card" @click="navigateToGiftCardPage">
    <div class="gift-card-image">
      <img :src="cardImageSrc" alt="Gift Card Image" />
    </div>
    <div class="gift-card-content">
      <div class="business-title-row">
        <div class="business-name">{{ businessName}}</div>
        <div class="rating-wrapper">
          <span class="star">★</span>
          <span class="rating-number">{{ rating }}</span>
        </div>
      </div>
      <div class="bussiness-address">{{ businessAddress }}</div>
      <h3 class="deal-name">{{ dealTitle }}</h3>
      <div class="price-wrapper">
        <span class="original-price">${{ originalPrice }}</span>
        <span class="current-price">${{ calculateDiscountedPrice(originalPrice, discountPercentage) }}</span>
        <span class="discount">{{ discountPercentage }}% OFF</span>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/default-image.png';

export default {
  name: 'GiftCard',
  props: {
    giftCardId: {
      type: String,
      required: true
    },
    businessName: {
      type: String,
      required: true
    },
    dealTitle: {
      type: String,
      required: true
    },
    rating: {
      type: Number,
      required: true
    },
    cardImage: {
      type: String,
      default: ''
    },
    originalPrice: {
      type: Number,
      required: true
    },
    discountPercentage: {
      type: Number,
      required: true
    },
    businessId: {
      type: String,
      required: true
    },
    businessAddress: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isInCart: false, // Track if the gift card is in the cart
      message: '',
      fading: false,
      isLoggedIn: false,
      token: localStorage.getItem('token'),
    };
  },
  computed: {
    cardImageSrc() {
      return this.cardImage || defaultImage;
    }
  },
  methods: {
    navigateToGiftCardPage() {
      this.$router.push(`/gift-card/${this.giftCardId}`).then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      });
    },
    calculateDiscountedPrice(originalPrice, discountPercentage) {
      return (originalPrice * (1 - discountPercentage / 100)).toFixed(2);
    },
    getStarClass(index) {
      if (this.rating === 0) {
        return 'blank';
      } else if (index <= Math.floor(this.rating)) {
        return 'filled';
      } else if (index === Math.ceil(this.rating) && this.rating % 1 !== 0) {
        return 'half-filled';
      } else {
        return 'blank';
      }
    }
  }
}
</script>

<style scoped>
.gift-card {
  border-radius: 8px;
  background: white;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  flex: 1 0 calc(25% - 10px);
  margin: 5px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 10px;
}

.gift-card-image {
  width: 100%;
  background: #f5f5f5;
  position: relative;
  padding-top: 56.25%;
}

.gift-card-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gift-card-content {
  padding: 12px;
}

.business-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.business-name {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  padding-top: 1%;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 5px;
}

.bussiness-address{
  font-size: 12px;
  color: #505050;
  font-weight: 600;
  margin-bottom: 12px;
  font-family: 'Roboto', sans-serif;
}

.rating-wrapper {
  display: flex;
  align-items: center;
  gap: 2px;
}

.star {
  color: #FFD700;
  font-size: 25px;
}

.rating-number {
  font-size: 15px;
  color: #333;
  font-weight: 600;
  padding-top:9%;
  padding-left: 4%
}

.deal-name {
  margin: 0 0 12px 0;
  font-size: 25px;
  line-height: 1.3;
  color: #333333;
  font-weight: 800;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 15px;
}

.price-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.original-price {
  color: #666;
  text-decoration: line-through;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.current-price {
  color: #2E8B57;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.discount {
  background-color: #E8F5E9;
  color: #2E8B57;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 1200px) {
  .gift-card {
    flex: 1 0 calc(33.333% - 10px);
  }
}

@media (max-width: 992px) {
  .gift-card {
    flex: 1 0 calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .gift-card {
    flex: 1 0 calc(100% - 10px);
    max-width: none;
    width: 90%;
    margin: 5px auto;
    margin-bottom: 20px;
  }

  .gift-card-image {
    height: auto;
  }
}
</style>
