<template>
  <div class="recent-orders-container">
    <h2>Recent Orders</h2>
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th>Order Number</th>
            <th>Items Ordered</th>
            <th>Total Cost</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in displayedOrders" :key="order.id">
            <td>{{ order.order_id }}</td>
            <td>{{ formatItems(order.items) }}</td>
            <td>${{ calculateTotalCost(order.items).toFixed(2) }}</td>
            <td>{{ formatDate(order.created_at) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <button v-if="orders.length > itemsToShow" @click="loadMore" class="load-more-button">Load More</button>
  </div>
</template>

<script>
export default {
  name: 'RecentOrders',
  props: {
    orders: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      itemsToShow: 10,
    };
  },
  computed: {
    displayedOrders() {
      return this.orders
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .slice(0, this.itemsToShow);
    }
  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    formatItems(items) {
      // Convert item names into a comma-separated string
      return items.map(item => item.name).join(', ');
    },
    calculateTotalCost(items) {
      // Calculate the total cost for all items in the order
      return items.reduce((sum, item) => sum + parseFloat(item.totalCost), 0);
    },
    loadMore() {
      this.itemsToShow += 10;
    }
  }
};
</script>

<style scoped>
.recent-orders-container {
  font-family: Arial, sans-serif;
}

.table-responsive {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #e0e0e0;
}

th, td {
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f0f0f0;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.status.completed {
  background-color: #4C6B30;
}

.status.cancelled {
  background-color: #f44336;
}

.status.pending {
  background-color: #FFC107;
}

.icon {
  cursor: pointer;
  margin-right: 5px;
}

.load-more-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.load-more-button:hover {
  background-color: #6E9F49;
}

@media (max-width: 768px) {
  th, td {
    padding: 8px;
  }

  .load-more-button {
    width: 100%;
    padding: 15px;
    font-size: 16px;
  }
}
</style>
