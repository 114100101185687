<template>
  <div class="notifications-container">
    <h1 class="title">Notification</h1>

    <div v-if="notifications.length === 0" class="no-notifications">
      You have no notifications.
    </div>

    <div v-else class="notifications-list">
      <!-- Today Section -->
      <div v-if="groupedNotifications.today.length" class="notification-group">
        <h2 class="notification-date">Today</h2>
        <div v-for="notification in groupedNotifications.today" :key="notification.id" class="notification-card">
          <div class="notification-avatar">
            <i class="bell-icon fas fa-bell"></i>
          </div>
          <div class="notification-content">
            <h3 class="notification-title">{{ notification.title }}</h3>
            <!-- Render HTML content for messageBody -->
            <div class="notification-body" v-html="notification.messageBody"></div>
          </div>
          <button class="remove-button" @click="removeNotification(notification.id)">Delete</button>
        </div>
      </div>

      <!-- Yesterday Section -->
      <div v-if="groupedNotifications.yesterday.length" class="notification-group">
        <h2 class="notification-date">Yesterday, {{ formatDate(yesterday) }}</h2>
        <div v-for="notification in groupedNotifications.yesterday" :key="notification.id" class="notification-card">
          <div class="notification-avatar">
            <i class="bell-icon fas fa-bell"></i>
          </div>
          <div class="notification-content">
            <h3 class="notification-title">{{ notification.title }}</h3>
            <!-- Render HTML content for messageBody -->
            <div class="notification-body" v-html="notification.messageBody"></div>
          </div>
          <button class="remove-button" @click="removeNotification(notification.id)">Delete</button>
        </div>
      </div>

      <!-- Older Section -->
      <div v-if="groupedNotifications.older.length" class="notification-group">
        <h2 class="notification-date">Older</h2>
        <div v-for="notification in groupedNotifications.older" :key="notification.id" class="notification-card">
          <div class="notification-avatar">
            <i class="bell-icon fas fa-bell"></i>
          </div>
          <div class="notification-content">
            <h3 class="notification-title">{{ notification.title }}</h3>
            <!-- Render HTML content for messageBody -->
            <div class="notification-body" v-html="notification.messageBody"></div>
          </div>
          <button class="remove-button" @click="removeNotification(notification.id)">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NotificationsPage',
  data() {
    return {
      notifications: [],
      today: new Date(),
      yesterday: new Date(new Date().setDate(new Date().getDate() - 1)),
    };
  },
  computed: {
    groupedNotifications() {
      const today = [];
      const yesterday = [];
      const older = [];

      this.notifications.forEach(notification => {
        const notificationDate = new Date(notification.createdAt);

        if (this.isToday(notificationDate)) {
          today.push(notification);
        } else if (this.isYesterday(notificationDate)) {
          yesterday.push(notification);
        } else {
          older.push(notification);
        }
      });

      return { today, yesterday, older };
    },
  },
  methods: {
    fetchNotifications() {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');
      if (user && user.id && token) {
        axios
          .get(`${process.env.VUE_APP_API_URL}/api/notifications/user-notifications`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              userId: user.id,
            },
          })
          .then(response => {
            this.notifications = response.data.notifications;
          })
          .catch(error => {
            console.error('Error fetching notifications:', error);
          });
      }
    },
    async removeNotification(notificationId) {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');
      if (user && user.id && token) {
        try {
          await axios.delete(
            `${process.env.VUE_APP_API_URL}/api/notifications/remove-notification`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                userId: user.id,
                notificationId: notificationId,
              },
            }
          );
          this.notifications = this.notifications.filter(notification => notification.id !== notificationId);
        } catch (error) {
          console.error('Error removing notification:', error);
        }
      }
    },
    stripHtmlTags(html) {
      const div = document.createElement('div');
      div.innerHTML = html;
      return div.textContent || div.innerText || '';
    },
    isToday(date) {
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },
    isYesterday(date) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return (
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()
      );
    },
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    },
  },
  mounted() {
    this.fetchNotifications();
  },
};
</script>

<style scoped>
/* General Layout */
.notifications-container {
  max-width: 900px;
  margin: 0 auto; /* Center-align notifications */
  padding: 20px;
}

.notification-body ::v-deep(img) {
  width: 300px;
  height: auto;
}
h1 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.notification-group {
  margin-bottom: 30px;
}

.notification-date {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #666;
}

.notification-card {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.notification-avatar {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(110, 159, 73, 0.19); /* 6E9F49 with 19% transparency */
  margin-right: 20px;
}

.bell-icon {
  font-size: 20px;
  color: #6e9f49; /* Bell icon color */
}

.notification-content {
  flex-grow: 1;
}

.notification-title {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
}

.notification-body {
  font-size: 14px;
  color: #666;
}

/* Remove Button */
.remove-button {
  background-color: #ff4d4d;
  border: none;
  border-radius: 20px;
  color: white;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.remove-button:hover {
  background-color: #ff3333;
}

.no-notifications {
  text-align: center;
  color: #777;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .notifications-container {
    margin: 0 20px; /* Add some margin on the sides for smaller screens */
  }

  h1 {
    font-size: 24px; /* Decrease font size for smaller screens */
  }

  .notification-card {
    flex-direction: column; /* Stack notification content vertically */
    align-items: flex-start; /* Align notification content to the left */
    padding: 20px 0; /* Increase padding for better spacing */
  }

  .notification-avatar {
    margin-right: 0; /* Remove right margin for better spacing */
    margin-bottom: 10px; /* Add some margin at the bottom for better spacing */
  }

  .notification-content {
    width: 100%; /* Make notification content take full width */
  }

  .notification-title {
    font-size: 18px; /* Decrease font size for smaller screens */
    margin-bottom: 5px; /* Decrease margin bottom for better spacing */
  }

  .notification-body {
    font-size: 16px; /* Decrease font size for smaller screens */
  }

  .remove-button {
    font-size: 12px; /* Decrease font size for smaller screens */
    padding: 6px 16px; /* Decrease padding for smaller buttons */
  }
}
</style>
