<template>
  <div class="dashboard-content">
    <MetaManager
        title="Admin - Create Business"
        description="Create a Local Business"
      />
    <div class="form-container">
      <h1>Create Business</h1>
      <form @submit.prevent="createBusiness">
        <div class="form-group">
          <label>Business Name:</label>
          <input type="text" v-model="business_name" class="input-field" required />
        </div>
        <div class="form-group">
          <label>Email:</label>
          <input type="email" v-model="business_info.email" class="input-field" required />
        </div>
        <div class="form-group">
          <label>Phone:</label>
          <input type="text" v-model="business_info.phone" class="input-field" required />
        </div>
        <div class="form-group">
          <label>Address:</label>
          <input type="text" v-model="business_info.address" class="input-field" required />
        </div>
        <div class="form-group">
          <label>Location:</label>
          <select v-model="location" class="input-field" required>
            <option value="" disabled>Select a location</option>
            <option value="Stark County, Ohio">Stark County, Ohio</option>
            <option value="Summit County, Ohio">Summit County, Ohio</option>
          </select>
        </div>
        <div class="form-group">
          <label>Category:</label>
          <select v-model="category" class="input-field" required>
            <option value="" disabled>Select a category</option>
            <option value="Restaurants">Restaurants</option>
            <option value="Beauty-spa">Beauty & Spas</option>
            <option value="Things-to-do">Things to do</option>
            <option value="Health-fitness">Health & Fitness</option>
            <option value="Automotive">Automotive</option>
            <option value="Home-services">Home Services</option>
            <option value="Retail">Retail</option>
            <option value="Technology">Technology</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div class="form-group">
          <label>Business Rep:</label>
          <input type="text" v-model="business_info.rep" class="input-field" required />
        </div>
        <div class="form-group">
          <label>Business Logo:</label>
          <input type="file" @change="onFileChange" class="input-field" required ref="logoInput" />
        </div>
        <div class="form-buttons">
          <button type="submit" class="submit-button">Create Business</button>
          <button type="button" @click="cancelCreate" class="cancel-button">Cancel</button>
        </div>
      </form>
    </div>
    <div v-if="isLoading" class="loading-overlay">
      <div class="loader"></div>
      <p>Creating the business, please wait...</p>
    </div>
    <div v-if="isCreated" class="created-overlay">
      <p>Business Created Successfully</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MetaManager from '../../components/MetaManager.vue';

export default {
  name: 'DashboardCreateBusiness',
  components: {
    MetaManager,
  },
  data() {
    return {
      business_name: '',
      business_info: {
        email: '',
        phone: '',
        address: '',
        links: [''],
        rep: ''
      },
      business_logo: null,
      location: '',
      category: '',  // Add category field
      isLoading: false, // State to control the loading spinner visibility
      isCreated: false, // State to control the "Business Created" message visibility
    };
  },
  methods: {
    onFileChange(e) {
      this.business_logo = e.target.files[0];
    },
    async createBusiness() {
      this.isLoading = true; // Show the loading spinner
      try {
        const formData = new FormData();
        formData.append('business_name', this.business_name);
        formData.append('show_event_calendar', false);
        formData.append('show_sales_post', false);
        formData.append('show_flyer_uploader', false);
        formData.append('show_food_menu', false);
        formData.append('business_info', JSON.stringify(this.business_info));
        formData.append('business_logo', this.business_logo);
        formData.append('location', this.location);
        formData.append('category', this.category);  // Append the selected category

        await axios.post(`${process.env.VUE_APP_API_URL}/api/updates/create-business`, formData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        // Simulate delay for loading spinner visibility
        setTimeout(() => {
          this.isLoading = false; // Hide the spinner
          this.isCreated = true; // Show the "Business Created" message

          // Hide the "Business Created" message after a few seconds
          setTimeout(() => {
            this.isCreated = false;
            this.clearForm(); // Clear form after creation
          }, 3000); // Adjust this value to control how long the message is shown
        }, 2000); // Adjust this delay as needed
      } catch (error) {
        console.error('Error creating business:', error);
        this.isLoading = false; // Ensure spinner is hidden in case of error
      }
    },
    cancelCreate() {
      this.clearForm();
    },
    clearForm() {
      this.business_name = '';
      this.business_info = {
        email: '',
        phone: '',
        address: '',
        links: ['']
      };
      this.business_logo = null;
      this.location = '';
      this.category = '';  // Clear category selection
      this.$refs.logoInput.value = '';
    }
  }
};
</script>

<style scoped>
.dashboard-content {
  padding: 20px;
  margin-left: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
  box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 0; /* Remove margin for sidebar */
    padding: 15px; /* Adjust padding for medium screens */
    height: 60vh;
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    padding: 10px; /* Adjust padding for small screens */
    flex-direction: column; /* Stack elements vertically */
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    padding: 5px; /* Adjust padding for extra small screens */
    padding-top: 250px;
  }
}

.form-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  position: relative;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 48%;
}

.submit-button {
  background-color: #4C6B30;
  color: white;
}

.submit-button:hover {
  background-color: #6E9F49;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4C6B30;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

.created-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.created-overlay p {
  font-size: 18px;
  color: #333;
}

p {
  font-size: 18px;
  color: #333;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
