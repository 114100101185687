import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js'; // Import the router
import axios from 'axios';
import { getSessionId } from './utils/session';
import socket from './utils/socket';

getSessionId();
function trackActiveUser() {
  const sessionId = localStorage.getItem('sessionId');
  const user = JSON.parse(localStorage.getItem('user')) || {};
  const data = {
    sessionId,
    page: window.location.pathname,
    username: user.fullName || 'Anonymous', // Replace with the actual username if available
    timestamp: new Date().toISOString(),
  };

  axios.post(`${process.env.VUE_APP_API_URL}/api/track-visit`, data)
  .catch((err) => console.error('Error tracking visit:', err));
  socket.emit('user-active', data);
}

// Track user activity on load
trackActiveUser();
window.addEventListener('popstate', trackActiveUser);
const app = createApp(App);

// Set up Axios interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 403) {
      // Token expired or not valid
      localStorage.removeItem('user');
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('isBusiness');
      localStorage.removeItem('token');
      localStorage.removeItem('shoppingBagItems');
      localStorage.removeItem('checkoutCartItems');
      localStorage.removeItem('billingDetails')
      location.reload();
      router.push('/login'); // Redirect to login page
    }
    return Promise.reject(error);
  }
);

app.use(router).mount('#app'); // Use the router in your app
