<template>
    <div>
      <MetaManager
        title="View All Incentives "
        description="Explore Stark Shopper for a comprehensive selection of incentives. View by category or see all available offers to find the best savings today"
      />
      <div class="browse-all-deals">
        <CategoryFilter :categories="categories" @category-selected="filterCoupons" :default-category="'all'" />
      </div>
      <div class="coupon-list">
        <CouponCard
            v-for="coupon in displayedCoupons"
            :key="coupon._id"
            :couponId="coupon._id"
            :dealTitle="coupon.dealtitle"
            :couponCode="coupon.couponcode[0]"
            :couponImage="coupon.image_links[0]"
            :rating="coupon.rating"
            :category="coupon.category"
            :businessName="coupon.business_name"
            :businessAddress="coupon.address"
            @save-coupon="addToShoppingCart"
            ref="couponCards"
        />
      </div>
      <div class="load-more-container" v-if="!allCouponsLoaded">
        <button @click="loadMoreCoupons">Load More Incentives</button>
      </div>
    </div>
  </template>
  
  <script>
  import CouponCard from '@/components/Coupon Components/CouponCard.vue';
  import CategoryFilter from '@/components/Frontend Nav Components/CategoryFilterBar.vue';
  import axios from 'axios';
  import MetaManager from '../../components/MetaManager.vue';
  
  export default {
    components: {
      CategoryFilter,
      CouponCard,
      MetaManager,
    },
    data() {
      return {
        categories: [
          { id: 'all', name: 'All' },
          { id: 'Restaurants', name: 'Restaurants' },
          { id: 'Beauty-spa', name: 'Beauty & Spas' },
          { id: 'Things-to-do', name: 'Things to do' },
          { id: 'Health-fitness', name: 'Health & Fitness' },
          { id: 'Automotive', name: 'Automotive' },
          { id: 'Home-services', name: 'Home Services' },
          { id: 'Retail', name: 'Retail' },
          { id: 'Technology', name: 'Technology' },
          { id: 'Other', name: 'Other' }
        ],
        coupons: [],
        displayedCoupons: [],
        selectedCategory: 'all',
        offset: 0,
        limit: 10,
        allCouponsLoaded: false,
      };
    },
    methods: {
      async loadCoupons(reset = false) {
        try {
            if (reset) {
            this.coupons = [];
            this.offset = 0;
            this.allCouponsLoaded = false;
            }
            const selectedLocation = localStorage.getItem('selectedLocation') || 'Stark County, Ohio';
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/data`, {
            params: {
                limit: this.limit,
                offset: this.offset,
                category: this.selectedCategory === 'all' ? '' : this.selectedCategory,
                location: selectedLocation,
            }
            });
            const newCoupons = response.data;
            if (reset) {
            this.coupons = newCoupons;
            } else {
            this.coupons = [...this.coupons, ...newCoupons];
            }
            this.allCouponsLoaded = newCoupons.length < this.limit;
            if (newCoupons.length > 0) {
            this.offset += this.limit; // Move the offset forward only if there are new coupons
            }
            this.updateDisplayedCoupons();
        } catch (error) {
            console.error('Error fetching coupons:', error);
        }
      },
      loadMoreCoupons() {
        this.loadCoupons();
      },
      filterCoupons(category) {
        this.selectedCategory = category.id;
        this.loadCoupons(true);
      },
      updateDisplayedCoupons() {
        this.displayedCoupons = this.coupons;
      },
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Smooth scrolling
        });
      },
      processDiscount(coupon) {
        const { discount, regularprice, spendamount, discounttype } = coupon;
        const isRegularPriceValid = regularprice[0] !== 0;
        const isSpendAmountValid = spendamount[0] !== 0;
  
        return {
          discount: discount[0],
          regularprice: isRegularPriceValid ? regularprice : null,
          spendamount: isSpendAmountValid ? spendamount : null,
          discounttype: discounttype[0]
        };
      },
      async addToShoppingCart(couponId) {
        const userId = this.getUserId();
        try {
          await axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/${userId}`, { couponId });
          this.$refs.couponCards.forEach(card => {
            if (card.couponId === couponId) {
              card.showMessage('Coupon added to saved coupons');
            }
          });
        } catch (error) {
          if (error.response && error.response.status === 400) {
            this.$refs.couponCards.forEach(card => {
              if (card.couponId === couponId) {
                card.showMessage('Coupon already saved');
              }
            });
          } else {
            this.$refs.couponCards.forEach(card => {
              if (card.couponId === couponId) {
                card.showMessage('Error adding coupon to shopping cart');
              }
            });
          }
        }
      },
      getUserId() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? user.id : null;
      }
    },
    mounted(){
      this.scrollToTop();
    }
  };
  </script>
  
  <style scoped>
  .coupon-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
  }
  
  .browse-all-deals {
    text-align: left;
    margin-left: 7%;
    margin-top: 50px;
    padding-bottom: 20px;
    font-family: 'Roboto', sans-serif;
  }
  
  .load-more-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  button {
  padding: 15px 25px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 17px;
  margin-bottom: 50px;
}

button:hover {
  background-color: #6E9F49;
}

.coupon-list :deep(.coupon-card) {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.coupon-list :deep(.coupon-card:hover) {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px){
  .browse-all-deals{
    margin-top: 25%;
    margin-left: 0%;
  }
}
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .browse-all-deals {
      margin-left: 5%;
      margin-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 150px;
    }
  
    button {
      padding: 8px 16px; /* Slightly reduce button size */
      font-size: 14px; /* Adjust font size for buttons */
    }

    .coupon-list .coupon-item {
      flex-direction: column;
      align-items: center;
  }

  .coupon-list :deep(.coupon-card:hover) {
    transform: translateY(-3px);
  }
  }
  
  @media (max-width: 480px) {
    .coupon-list {
      flex-direction: column;
      align-items: center;
    }
  
    .browse-all-deals {
      margin-left: 9%;
      padding-bottom: 5px;
    }
  
    .load-more-container {
      margin-bottom: 10px; /* Reduce bottom margin */
    }
  
    button {
      padding: 6px 12px; /* Further reduce button size */
      font-size: 12px; /* Adjust font size for smaller screens */
    }

    .coupon-list .coupon-item {
    flex-basis: calc(50% - 20px); /* Adjust for larger screens, two items per row */
  }

  .coupon-list :deep(.coupon-card:hover) {
    transform: translateY(-2px);
  }
  }
  </style>
  