<template>
  <div class="dashboard-admin-sales">
    <MetaManager
        title="Admin - Sales"
        description="Sales from local businesses"
      />
    <h1>Sales</h1>
    <div class="date-range-picker">
      <label for="start-date">Start Date:</label>
      <input type="date" v-model="startDate" id="start-date">
      <label for="end-date">End Date:</label>
      <input type="date" v-model="endDate" id="end-date">
      <button @click="fetchFinancialData">Fetch Data</button>
    </div>
    <div class="summary-boxes">
      <div class="summary-box">
        <h2>Total Orders</h2>
        <p>{{ totalOrders }}</p>
      </div>
      <div class="summary-box">
        <h2>Total Payouts This Month</h2>
        <p>${{ totalPayouts.toFixed(2) }}</p>
      </div>
      <div class="summary-box">
        <h2>Our Profit</h2>
        <p>${{ totalProfit.toFixed(2) }}</p>
      </div>
    </div>
    <div class="orders-container">
      <table class="orders-table desktop-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Business ID</th>
            <th>Total Amount</th>
            <th>Fees</th>
            <th>TrioTech Profit</th>
            <th>Payout to Business</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in displayedOrders" :key="order.id">
            <td>{{ order.order_id }}</td>
            <td>{{ order.business_id }}</td>
            <td>${{ parseFloat(order.total_before_fee).toFixed(2) }}</td>
            <td>${{ parseFloat(order.stripe_fee).toFixed(2) }}</td>
            <td>${{ parseFloat(order.shop_stark_profit).toFixed(2) }}</td>
            <td>${{ parseFloat(order.payout_to_business).toFixed(2) }}</td>
            <td>{{ new Date(order.created_at).toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mobile-cards">
        <div v-for="order in displayedOrders" :key="order.id" class="order-card">
          <p><strong>Order ID:</strong> {{ order.order_id }}</p>
          <p><strong>Business ID:</strong> {{ order.business_id }}</p>
          <p><strong>Total Amount:</strong> ${{ parseFloat(order.total_before_fee).toFixed(2) }}</p>
          <p><strong>Fees:</strong> ${{ parseFloat(order.stripe_fee).toFixed(2) }}</p>
          <p><strong>TrioTech Profit:</strong> ${{ parseFloat(order.shop_stark_profit).toFixed(2) }}</p>
          <p><strong>Payout to Business:</strong> ${{ parseFloat(order.payout_to_business).toFixed(2) }}</p>
          <p><strong>Created At:</strong> {{ new Date(order.created_at).toLocaleString() }}</p>
        </div>
      </div>
      <div class="load-more-container" v-if="hasMoreOrders">
        <button @click="loadMore" class="load-more-button">Load More</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MetaManager from '../../components/MetaManager.vue';


export default {
  name: 'DashboardAdminSales',
  components: {
    MetaManager,
  },
  data() {
    return {
      totalOrders: 0,
      totalPayouts: 0,
      totalProfit: 0,
      orders: [],
      startDate: '',
      endDate: '',
      currentPage: 1,
      ordersPerPage: 10
    };
  },
  computed: {
    displayedOrders() {
      return this.orders.slice(0, this.currentPage * this.ordersPerPage);
    },
    hasMoreOrders() {
      return this.displayedOrders.length < this.orders.length;
    }
  },
  methods: {
    setDefaultDateRange() {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

      this.startDate = firstDayOfMonth.toISOString().split('T')[0];
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      this.endDate = lastDayOfMonth.toISOString().split('T')[0];
    },
    async fetchFinancialData() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/payment/financials`, {
          params: {
            startDate: this.startDate,
            endDate: this.endDate
          }
        });
        this.totalOrders = response.data.totalOrders;
        this.totalPayouts = response.data.totalPayouts;
        this.totalProfit = response.data.totalProfit;
        this.orders = response.data.orders;
        this.currentPage = 1; // Reset to first page when new data is fetched
      } catch (error) {
        console.error('Error fetching financial data:', error);
      }
    },
    loadMore() {
      this.currentPage++;
    }
  },
  mounted(){
    this.setDefaultDateRange();
    this.fetchFinancialData();
  }
};
</script>


<style scoped>
.dashboard-admin-sales {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  margin-left: 300px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.date-range-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.date-range-picker label {
  font-weight: bold;
}

.date-range-picker input {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.date-range-picker button {
  padding: 8px 15px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.date-range-picker button:hover {
  background-color: #6E9F49;
}

.summary-boxes {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.summary-box {
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.summary-box h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.desktop-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.desktop-table th,
.desktop-table td {
  padding: 12px;
  border: 1px solid #ccc;
  text-align: left;
}

.desktop-table th {
  background: #f2f2f2;
  font-weight: bold;
}

.desktop-table tr:nth-child(even) {
  background: #f9f9f9;
}

.mobile-cards {
  display: none;
}

.order-card {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.order-card p {
  margin: 5px 0;
}

.load-more-container {
  text-align: center;
  margin-top: 20px;
}

.load-more-button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.load-more-button:hover {
  background-color: #6E9F49;
}

@media(max-width: 1024px){
  .dashboard-admin-sales{
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .dashboard-admin-sales {
    margin-left: 0;
    padding: 10px;
  }

  .date-range-picker {
    flex-direction: column;
    align-items: stretch;
  }

  .summary-box {
    min-width: calc(50% - 20px);
  }

  .desktop-table {
    display: none;
  }

  .mobile-cards {
    display: block;
  }
}

@media (max-width: 480px) {
  .summary-box {
    width: 95%;
  }
}
</style>