<template>
  <div class="step-images">
    <h2>Step 3: Images</h2>

    <!-- Main image upload box -->
    <div v-if="images.length === 0" class="upload-box" @click="triggerFileUpload" @drop.prevent="handleDrop" @dragover.prevent>
      <p>Upload or drag and drop here</p>
      <input type="file" @change="handleFileUpload" ref="fileInput" multiple hidden />
    </div>

    <!-- Display the selected image with navigation arrows -->
    <div v-else class="main-image-container">
      <button class="main-arrow left" @click="prevImage" :disabled="selectedImageIndex === 0">&#9664;</button>
      <div class="main-image">
        <img :src="images[selectedImageIndex].url" alt="Main Image" />
      </div>
      <button class="main-arrow right" @click="nextImage" :disabled="selectedImageIndex === images.length - 1">&#9654;</button>
    </div>

    <!-- Thumbnails of uploaded images with navigation arrows -->
    <div class="thumbnail-slider-container">
      <button class="thumbnail-arrow left" v-if="images.length > thumbnailsPerPage" @click="prevBatch" :disabled="currentBatch === 0">
        <span>&#9664;</span>
      </button>
      <div class="thumbnail-slider">
        <div class="thumbnail-container">
          <div class="thumbnail-item" v-for="(image, index) in visibleThumbnails" :key="index">
            <div class="image-preview" @click="selectImage(index + currentBatch * thumbnailsPerPage)">
              <img :src="image.url" :alt="'Image ' + (index + 1)" />
            </div>
            <button class="delete-button" @click="confirmDelete(index + currentBatch * thumbnailsPerPage)">X</button>
          </div>
        </div>
      </div>
      <button class="thumbnail-arrow right" v-if="images.length > thumbnailsPerPage" @click="nextBatch" :disabled="(currentBatch + 1) * thumbnailsPerPage >= images.length">
        <span>&#9654;</span>
      </button>
    </div>

    <!-- Smaller upload box for more images -->
    <div class="upload-more-box" v-if="images.length > 0" @click="triggerMoreFileUpload" @drop.prevent="handleDrop" @dragover.prevent>
      <p>Upload more or drag here</p>
      <input type="file" @change="handleFileUpload" ref="moreFileInput" multiple hidden />
    </div>

    <!-- Hidden div for coupon preview for image generation -->
      <div ref="couponPreview" class="coupon-preview" style="display: none; width: 800px; height: 400px;">
      <div class="printable-coupon" style="width: 100%; height: 100%; background-color: #f9f9f9; padding: 20px; box-sizing: border-box; font-family: Arial, sans-serif; position: relative; border: 5px dashed #000;">
        <div class="coupon-header" style="display: flex; justify-content: space-between; margin-bottom: 10px; height: 100px;">
          <div class="logo-container" style="width: 300px; height: 100%; display: flex;">
            <img :src="businessLogo" alt="Business Logo" style="max-width: 100%; max-height: 100%; object-fit: contain;" />
          </div>
          <div class="coupon-details" style="text-align: left; flex: 1; margin-left: 30px;">
            <h2 style="margin: 0; font-size: 30px; line-height: 1.1;">{{ couponDetails.dealTitle }}</h2>
            <p style="margin: 2px 0; font-size: 20px;">Incentive Code: {{ couponDetails.couponCode }}</p>
            <p style="margin: 2px 0; font-size: 20px;">EXP Date: {{ couponDetails.expirationDate }}</p>
            <img ref="barcodeImage" alt="Barcode" style="max-width: 100%; height: auto;" />
          </div>
        </div>
        
        <div style="display: flex; margin-top: 8%; flex-direction: column; justify-content: space-between; height: calc(100% - 500px);">
        <div class="coupon-content" ref="couponContent">
          <h3 style="margin: 0 0 3px 0; font-size: 14px; font-weight: bold;">About this Incentive:</h3>
          <div class="deal-details" :style="getDealDetailsStyle">
            <div v-html="formatDealDetails(couponDetails.dealDetails, 4)"></div>
          </div>
        </div>
        </div>

        <div class="fine-print" ref="finePrint" :style="getFinePrintStyle">
          <p style="margin: 0 0 2px 0; font-weight: bold;">RESTRICTIONS:</p>
          <div>{{ stripHtml(couponDetails.dealFinePrint) }}</div>
        </div>
      </div>
    </div>

    <!-- Delete confirmation dialog -->
    <div v-if="showDeleteConfirm" class="delete-confirmation">
      <p>Are you sure you want to delete this image?</p>
      <button @click="deleteImage">Yes</button>
      <button @click="cancelDelete">No</button>
    </div>
  </div>
</template>


<script>
import html2canvas from 'html2canvas';
import JsBarcode from 'jsbarcode';

export default {
  name: 'StepImages',
  data() {
    return {
      images: [],
      selectedImageIndex: 0,
      currentBatch: 0,
      showDeleteConfirm: false,
      deleteIndex: null,
      windowWidth: window.innerWidth,
      businessLogo: '',
      couponDetails: {
        dealTitle: '',
        dealDetails: '',
        dealFinePrint: '',
        couponCode: '',
        expiration: '',
        expirationDate: '', // This will store the formatted expiration date
      },
      generatingImage: false,
      lastGeneratedHash: '',
      contentScaling: {
        dealDetailsFontSize: 25,
        finePrintFontSize: 40,
        contentHeight: 180,
      }
    };
  },
  computed: {
    thumbnailsPerPage() {
      return this.windowWidth <= 480 ? 3 : 6;
    },
    visibleThumbnails() {
      const start = this.currentBatch * this.thumbnailsPerPage;
      const end = start + this.thumbnailsPerPage;
      return this.images.slice(start, end);
    },
    getContentStyle() {
      return {
        margin: '8px 0',
        height: `${this.contentScaling.contentHeight}px`,
      };
    },
    getDealDetailsStyle() {
      return {
        fontSize: `${this.contentScaling.dealDetailsFontSize}px`,
        lineHeight: '1.5',
      };
    },
    getFinePrintStyle() {
      return {
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        right: '20px',
        fontSize: `${this.contentScaling.finePrintFontSize}px`,
        lineHeight: '1.5',
      };
    },
  },
  methods: {
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    triggerMoreFileUpload() {
      this.$refs.moreFileInput.click();
    },
    handleFileUpload(event) {
      const files = event.target.files;
      this.processFiles(files);
    },
    handleDrop(event) {
      const files = event.dataTransfer.files;
      this.processFiles(files);
    },
    processFiles(files) {
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.compressImage(e.target.result, 0.8, (compressedImage) => {
            this.images.push({ url: compressedImage, name: file.name });
            if (this.images.length === 1) {
              this.selectedImageIndex = 0;
            }
            this.saveImagesToLocalStorage();
          });
        };
        reader.readAsDataURL(file);
      });
    },
    compressImage(dataURL, quality, callback) {
      const img = new Image();
      img.src = dataURL;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        callback(canvas.toDataURL('image/jpeg', quality));
      };
    },
    calculateExpiration() {
      const today = new Date();
      let expirationDate;

      if (this.couponDetails.expiration !== 'exact') {
        // Predefined expiration dates (5, 10, 15, 30 days)
        const days = parseInt(this.couponDetails.expiration, 10);
        expirationDate = new Date(today.setDate(today.getDate() + days));
      } else {
        // Exact expiration date
        expirationDate = new Date(this.couponDetails.exactExpirationDate);
      }

      // Format the expiration date as MM-DD-YYYY
      const month = (expirationDate.getMonth() + 1).toString().padStart(2, '0');
      const day = expirationDate.getDate().toString().padStart(2, '0');
      const year = expirationDate.getFullYear();

      this.couponDetails.expirationDate = `${month}-${day}-${year}`;
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    prevImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      }
    },
    prevBatch() {
      if (this.currentBatch > 0) {
        this.currentBatch--;
      }
    },
    nextBatch() {
      if ((this.currentBatch + 1) * this.thumbnailsPerPage < this.images.length) {
        this.currentBatch++;
      }
    },
    saveImagesToLocalStorage() {
      const imagesToSave = this.images.map((image, index) => ({
        url: image.url,
        name: image.name,
        index
      }));
      try {
        localStorage.setItem('uploadedImages', JSON.stringify(imagesToSave));
      } catch (e) {
        console.error('Failed to save images to local storage:', e);
      }
    },
    loadLocalStorageData() {
      try {
        const savedDetails = JSON.parse(localStorage.getItem('dealDetails'));
        const savedImages = JSON.parse(localStorage.getItem('uploadedImages'));
        const savedOptions = JSON.parse(localStorage.getItem('options'));
        const user = JSON.parse(localStorage.getItem('user'));
        const lastGeneratedHash = localStorage.getItem('lastGeneratedCouponHash');

        if (savedDetails) {
          this.couponDetails = savedDetails;
        }
        if (savedImages) {
          this.images = savedImages;
        }
        if (savedOptions && savedOptions[0]?.couponCode) {
          this.couponDetails.couponCode = savedOptions[0].couponCode;
        }
        if (user && user.businessLogo) {
          this.businessLogo = user.businessLogo;
        }
        if (lastGeneratedHash) {
          this.lastGeneratedHash = lastGeneratedHash;
        }

        // Check if we need to regenerate the coupon image
        this.checkAndRegenerateCouponImage();
      } catch (e) {
        console.error('Failed to load data from local storage:', e);
      }
    },
    async generateCouponImage() {
      this.calculateExpiration();
      this.generatingImage = true;
      const couponElement = this.$refs.couponPreview;

      try {
        // Generate and set barcode
        const barcodeDataUrl = await this.generateBarcodeDataUrl(this.couponDetails.couponCode);
        const barcodeImg = this.$refs.barcodeImage;
        barcodeImg.src = barcodeDataUrl;

        // Adjust content scaling
        await this.adjustContentScaling();

        // Make the preview visible for html2canvas
        couponElement.style.display = 'block';
        
        // Wait for all images to load
        await this.waitForImages(couponElement);

        // Generate the image
        const canvas = await html2canvas(couponElement, {
          width: 800,
          height: 400,
          scale: 2,
          backgroundColor: null,
          logging: false,
        });

        const imageDataUrl = canvas.toDataURL('image/png');

        if (imageDataUrl) {
          this.images = this.images.filter(img => img.name !== 'Generated Coupon Image');
          this.images.unshift({ url: imageDataUrl, name: 'Generated Coupon Image' });
          this.selectedImageIndex = 0;
          this.lastGeneratedHash = this.generateCouponDataHash();
          localStorage.setItem('lastGeneratedCouponHash', this.lastGeneratedHash);
          this.saveImagesToLocalStorage();
        }
      } catch (error) {
        console.error('Error generating image:', error);
      } finally {
        this.generatingImage = false;
        couponElement.style.display = 'none';
      }
    },
    generateBarcodeDataUrl(code) {
      return new Promise((resolve) => {
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, code, {
          format: "CODE128",
          width: 1.5,
          height: 40,
          displayValue: true,
          fontSize: 10,
          margin: 5
        });
        resolve(canvas.toDataURL('image/png'));
      });
    },
    generateCouponDataHash() {
      const relevantData = {
        businessLogo: this.businessLogo,
        ...this.couponDetails
      };
      return JSON.stringify(relevantData);
    },
    checkAndRegenerateCouponImage() {
      const currentHash = this.generateCouponDataHash();
      if (currentHash !== this.lastGeneratedHash) {
        this.generateCouponImage();
      }
    },
    formatDealDetails(html, maxLines) {
      if (!html) return '';
      
      // Remove existing styles
      let cleanHtml = html.replace(/style="[^"]*"/g, '');
      
      // Split content into lines (paragraphs or line breaks)
      let lines = cleanHtml.split(/(?:<\/p>|<br>|<br\/>|<br \/>)/).filter(line => {
        // Remove HTML tags and trim
        const cleanLine = line.replace(/<[^>]+>/g, '').trim();
        return cleanLine.length > 0;
      });

      // Truncate to max lines if needed
      if (lines.length > maxLines) {
        lines = lines.slice(0, maxLines);
        lines.push('...');
      }

      // Rebuild HTML with consistent formatting
      return lines.map(line => {
        // Clean the line of any remaining HTML tags
        let cleanLine = line.replace(/<[^>]+>/g, '').trim();
        return `<p style="margin: 0 0 3px 0; line-height: 1.2;">${cleanLine}</p>`;
      }).join('');
    },
    stripHtml(html) {
      if (!html) return '';
      const tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },

    async adjustContentScaling() {
      const couponElement = this.$refs.couponPreview;
      const contentElement = this.$refs.couponContent;
      const finePrintElement = this.$refs.finePrint;
      
      // Start with default sizes
      this.contentScaling.dealDetailsFontSize = 14;
      this.contentScaling.finePrintFontSize = 10;
      this.contentScaling.contentHeight = 180;

      // Make element visible for measurements
      couponElement.style.display = 'block';

      // Function to check if content fits
      const checkContentFit = () => {
        const contentOverflow = contentElement.scrollHeight > contentElement.clientHeight;
        const finePrintOverflow = finePrintElement.scrollHeight > 80; // Max allowed height for fine print
        return !contentOverflow && !finePrintOverflow;
      };

      // Adjust sizes until content fits
      while (!checkContentFit() && this.contentScaling.dealDetailsFontSize > 8) {
        this.contentScaling.dealDetailsFontSize -= 0.5;
        this.contentScaling.finePrintFontSize -= 0.25;
        await this.$nextTick();
      }

      // Hide element again
      couponElement.style.display = 'none';
    },

    waitForImages(element) {
      const images = element.getElementsByTagName('img');
      return Promise.all(Array.from(images).map(img => {
        if (img.complete) {
          return Promise.resolve();
        } else {
          return new Promise(resolve => {
            img.onload = resolve;
            img.onerror = resolve;
          });
        }
      }));
    },
    confirmDelete(index) {
      this.showDeleteConfirm = true;
      this.deleteIndex = index;
    },
    deleteImage() {
      if (this.deleteIndex !== null) {
        this.images.splice(this.deleteIndex, 1);
        this.saveImagesToLocalStorage();
        this.showDeleteConfirm = false;
        this.deleteIndex = null;
        if (this.selectedImageIndex >= this.images.length) {
          this.selectedImageIndex = this.images.length - 1;
        }
      }
    },
    cancelDelete() {
      this.showDeleteConfirm = false;
      this.deleteIndex = null;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    couponDetails: {
      deep: true,
      handler() {
        this.checkAndRegenerateCouponImage();
      }
    },
    businessLogo() {
      this.checkAndRegenerateCouponImage();
    }
  },
  mounted() {
    this.loadLocalStorageData();
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
};

</script>

<style scoped>
/* Styles remain similar to previous versions */
.step-images {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-generation {
  margin-bottom: 20px;
}

.upload-box {
  width: 800px;
  height: 300px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.upload-more-box {
  width: 800px;
  height: 100px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.upload-box p,
.upload-more-box p {
  margin: 0;
  text-align: center;
}

.main-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 825px;
  height: 400px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

.main-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.main-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.thumbnail-slider-container {
  display: flex;
  align-items: center;
  width: 730px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.thumbnail-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100%);
}

.thumbnail-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.thumbnail-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-preview {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 5px;
  margin-right: 20px;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.delete-button {
  padding: 5px 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: darkred;
}

.main-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.main-arrow.left {
  left: 10px;
}

.main-arrow.right {
  right: 10px;
}

.thumbnail-arrow {
  width: 10px;
  height: 100px;
  background-color: #4C6B30;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: relative;
}

.thumbnail-arrow.left {
  margin-right: 5px;
}

.thumbnail-arrow.right {
  margin-left: 5px;
}

.main-arrow:disabled,
.thumbnail-arrow:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.delete-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.delete-confirmation p {
  margin-bottom: 10px;
}

.delete-confirmation button {
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #6E9F49;
}

:deep(.deal-details) {
  line-height: 1.2 !important;
}

:deep(.deal-details *) {
  line-height: 1.2 !important;
  margin: 0 0 3px 0 !important;
}

:deep(.deal-details p) {
  margin: 0 0 3px 0 !important;
}

:deep(.deal-details h1),
:deep(.deal-details h2),
:deep(.deal-details h3),
:deep(.deal-details h4),
:deep(.deal-details h5),
:deep(.deal-details h6) {
  margin: 0 0 3px 0 !important;
  font-weight: normal !important;
}

:deep(.deal-details p:last-child) {
  margin-bottom: 0 !important;
}

:deep(.deal-details p:last-child.truncated) {
  position: relative;
}

:deep(.deal-details p:last-child.truncated::after) {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
  padding-left: 40px;
  background: linear-gradient(to right, transparent, #f9f9f9 20%);
}

@media (max-width: 1024px) {
  .upload-box,
  .upload-more-box {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  .upload-box,
  .upload-more-box {
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .upload-box,
  .upload-more-box {
    max-width: 100%;
    height: 300px;
  }

  .main-image-container {
    width: 100%;
  }

  .thumbnail-slider-container {
    width: 100%;
  }
}
</style>
