<template>
  <div class="dashboard-content">
    <MetaManager
        title="View Incentives"
        description="View all of your created incentives"
      />
    <h1>View Incentives</h1>
    <div class="coupons-header">
      <button @click="editCoupon" :disabled="!selectedCouponId">Edit</button>
    </div>
    <div class="coupons-table-container">
      <div v-if="showSavedMessage" class="saved-message">
        Saved
      </div>
      <table class="coupons-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Deal Title</th>
            <th>Category</th>
            <th>Incentive Code</th>
            <th>Published</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="coupon in coupons" :key="coupon._id">
            <tr>
              <td><input type="radio" :value="coupon._id" v-model="selectedCouponId" /></td>
              <td>{{ coupon.dealtitle }}</td>
              <td>{{ coupon.category }}</td>
              <td>{{ extractCouponCode(coupon) }}</td>
              <td>
                <label class="switch">
                  <input type="checkbox" :checked="coupon.ispublished" @change="togglePublish(coupon._id, $event.target.checked)" />
                  <span class="slider"></span>
                </label>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MetaManager from '../../components/MetaManager.vue';

export default {
  name: 'ViewCoupons',
  components: {
    MetaManager,
  },
  data() {
    return {
      coupons: [],
      selectedCouponId: null,
      showSavedMessage: false,
      token: localStorage.getItem('token'),
    };
  },
  methods: {
    extractCouponCode(coupon) {
      // Extract the first coupon code string from the options array
      return Array.isArray(coupon.couponcode) ? coupon.couponcode[0] : coupon.couponcode || 'N/A';
    },
    async fetchCoupons() {
      try {
        const businessId = JSON.parse(localStorage.getItem('user')).business_id;
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/view-coupons/${businessId}`, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.coupons = response.data.map(coupon => ({
          ...coupon,
          showDetails: false,
          options: this.getCouponOptions(coupon),
        }));
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
    },
    editCoupon() {
      if (this.selectedCouponId) {
        this.$router.push(`/dashboard/edit-incentive/${this.selectedCouponId}`);
      }
    },
    async togglePublish(id, ispublished) {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/update-publish/${id}`, { ispublished }, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        console.log('Publish status updated:', response.data);
        this.showSavedMessage = true;
        setTimeout(() => {
          this.showSavedMessage = false;
        }, 3000);
      } catch (error) {
        console.error('Error updating publish status:', error);
      }
    },
    getCouponOptions(coupon) {
      const options = [];
      if (Array.isArray(coupon.regularprice)) {
        coupon.regularprice.forEach((_, index) => {
          options.push({
            regularprice: coupon.regularprice[index],
            couponcode: coupon.couponcode[index],
            discount: coupon.discount[index],
            maxpermonth: coupon.maxpermonth[index],
            spendamount: coupon.spendamount[index] || null,
          });
        });
      } else {
        options.push({
          regularprice: coupon.regularprice,
          couponcode: coupon.couponcode,
          discount: coupon.discount,
          maxpermonth: coupon.maxpermonth,
          spendamount: coupon.spendamount,
        });
      }
      return options;
    },
  },
  mounted() {
    this.fetchCoupons();
  },
};
</script>

<style scoped>
.dashboard-content {
  margin-left: 300px;
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.dashboard-content h1 {
  margin-top: 0;
}

.coupons-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.coupons-table-container {
  overflow-y: auto;
  max-height: 600px;
}

.coupons-table,
.details-table {
  width: 100%;
  border-collapse: collapse;
}

.coupons-table th,
.coupons-table td,
.details-table th,
.details-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.coupons-table th,
.details-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.coupons-table td,
.details-table td {
  vertical-align: top;
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4C6B30;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.saved-message {
  margin-top: 10px;
  color: green;
  font-weight: bold;
  animation: fadeOut 3s forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

/* Custom Radio Button */
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #4C6B30;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type="radio"]:checked {
  background-color: #4C6B30;
}

input[type="radio"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
}

input[type="radio"]:hover {
  border-color: #6E9F49;
}

input[type="radio"]:checked:hover {
  background-color: #6E9F49;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 0px; /* Adjust margin for smaller screens */
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 200px; /* Adjust margin for smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .coupons-header {
    justify-content: center; /* Center header on smaller screens */
    margin-bottom: 8px; /* Adjust margin for smaller screens */
  }

  button {
    padding: 8px 16px; /* Adjust button padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    margin-left: 0; /* Remove margin for very small screens */
    padding: 10px; /* Adjust padding for very small screens */
  }

  .coupons-header {
    flex-direction: column; /* Stack header items vertically */
    align-items: center; /* Center header items */
    margin-bottom: 6px; /* Adjust margin for very small screens */
  }

  .coupons-header button {
    align-self: flex-start; /* Move button to the left */
  }

  .coupons-table-container {
    max-height: 600px; /* Increase table height */
  }

  button {
    padding: 6px 12px; /* Adjust button padding for very small screens */
  }
}
</style>