import { io } from 'socket.io-client';

const SOCKET_URL = process.env.NODE_ENV === 'production' 
  ? `wss://${window.location.hostname}`  // Use WSS for production
  : 'ws://localhost:3000';               // Use WS for development

const socket = io(SOCKET_URL, {
  path: '/socket.io',
  transports: ['websocket'],
  secure: process.env.NODE_ENV === 'production',
  rejectUnauthorized: false,
  autoConnect: true,
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 5
});

// Socket event listeners for debugging
socket.on('connect', () => {
});

socket.on('connect_error', (error) => {
  console.error('Socket connection error:', error);
});

socket.on('disconnect', (reason) => {
  console.log('Socket disconnected:', reason);
});

export default socket;