<template>
  <div class="dashboard-content">
    <MetaManager
        title="View all gift cards"
        description="view all the gift cards that were created for your business"
      />
    <h1>View Gift Cards</h1>
    <div class="giftCards-header">
      <button @click="showChangeRequestModal = true" :disabled="!isRequestChangeEnabled">Request Change</button>
      <button @click="exportToCSV" :disabled="!isRequestChangeEnabled">Export to Excel</button>
    </div>
    <div class="giftCards-table-container">
      <table class="giftCards-table">
        <thead>
          <tr>
            <th>
              <input type="checkbox" @change="toggleSelectAll" :checked="isAllSelected" />
            </th>
            <th>Deal Title</th>
            <th>Category</th>
            <th>Options</th>
            <th>Code</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="giftCard in state.giftCards" :key="giftCard._id">
            <td>
              <input type="checkbox" :value="giftCard._id" v-model="state.selectedGiftCardIds" @change="updateRequestChangeButton" />
            </td>
            <td>{{ giftCard.dealtitle }}</td>
            <td>{{ giftCard.category }}</td>
            <td>
              <div class="options-container">
                <template v-if="hasOptions(giftCard)">
                  <div v-for="(_, index) in getOptionsLength(giftCard)" :key="index" class="option-row">
                    <strong>Option {{ index + 1 }}:</strong>
                    <div class="option-details">
                      <span class="option-item">
                        <label>Price:</label> ${{ Array.isArray(giftCard.currentprice) ? giftCard.currentprice[index] : giftCard.currentprice }} |
                      </span>
                      <span class="option-item">
                        <label> Discount:</label> {{ Array.isArray(giftCard.discount) ? giftCard.discount[index] : giftCard.discount }}% |
                      </span>
                      <span class="option-item">
                        <label>Max Purchase:</label> {{ Array.isArray(giftCard.maxperpurchase) ? giftCard.maxperpurchase[index] : giftCard.maxperpurchase }} |
                      </span>
                      <span class="option-item">
                        <label> Stock:</label> {{ Array.isArray(giftCard.numberinstock) ? giftCard.numberinstock[index] : giftCard.numberinstock }}
                      </span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="option-row">
                    <div class="option-details">
                      <span class="option-item">
                        <label>Price:</label> ${{ giftCard.currentprice }}
                      </span>
                      <span class="option-item">
                        <label> Discount:</label> {{ giftCard.discount }}%
                      </span>
                      <span class="option-item">
                        <label>Max Purchase:</label> {{ giftCard.maxperpurchase }}
                      </span>
                      <span class="option-item">
                        <label> Stock:</label> {{ giftCard.numberinstock }}
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </td>
            <td>
              <button @click="toggleCodes(giftCard._id)">
                {{ state.showCodes[giftCard._id] ? 'Hide Codes' : 'Show Codes' }}
              </button>
            </td>
          </tr>
          <tr v-for="giftCard in giftCardsWithShownCodes" :key="giftCard._id">
            <td colspan="5">
              <div class="giftCodes-container">
                <template v-if="hasOptions(giftCard)">
                  <div v-for="(codes, index) in giftCard.giftcardcodes" :key="index" class="option-codes">
                    <template v-if="codes.length > 0">
                      <h2>Gift Card Numbers - Option {{ index + 1 }}</h2>
                      <table class="giftCodes-table">
                        <thead>
                          <tr>
                            <th>Gift Card Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="code in codes" :key="code">
                            <td>{{ formatCode(code) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                    <div v-else-if="index === 0" class="no-codes-message">
                      No gift card numbers available for option {{ index + 1 }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <h2>Gift Card Numbers</h2>
                  <table class="giftCodes-table" v-if="giftCard.giftcardcodes[0].length > 0">
                    <thead>
                      <tr>
                        <th>Gift Card Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="code in giftCard.giftcardcodes[0]" :key="code">
                        <td>{{ formatCode(code) }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="no-codes-message">
                    No gift card numbers available
                  </div>
                </template>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="showChangeRequestModal" class="modal">
      <div class="modal-content">
        <h2>Request Change</h2>
        <textarea v-model="changeRequestDetails" placeholder="Enter change details" class="description-box"></textarea>
        <div class="modal-actions">
          <button @click="submitChangeRequest">Submit</button>
          <button @click="showChangeRequestModal = false">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted, ref } from 'vue';
import MetaManager from '../../components/MetaManager.vue';

export default {
  name: 'ViewGiftCards',
  components: {
    MetaManager,
  },
  setup() {
    const state = reactive({
      giftCards: [],
      selectedGiftCardIds: [],
      showCodes: {},
      token: localStorage.getItem('token'),
    });

    const hasOptions = (giftCard) => {
      return Array.isArray(giftCard.currentprice) || 
             Array.isArray(giftCard.discount) ||
             Array.isArray(giftCard.maxperpurchase) ||
             Array.isArray(giftCard.numberinstock);
    };

    const getOptionsLength = (giftCard) => {
      if (Array.isArray(giftCard.currentprice)) return giftCard.currentprice.length;
      if (Array.isArray(giftCard.discount)) return giftCard.discount.length;
      if (Array.isArray(giftCard.maxperpurchase)) return giftCard.maxperpurchase.length;
      if (Array.isArray(giftCard.numberinstock)) return giftCard.numberinstock.length;
      return 1;
    };

    const showChangeRequestModal = ref(false);
    const changeRequestDetails = ref('');

    const fetchGiftCards = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user.business_id) {
          console.error('User or business_id not found in localStorage');
          return;
        }

        const businessId = user.business_id;
        const response = await fetch(`${process.env.VUE_APP_API_URL}/api/view-gift-cards/${businessId}`, {
          headers: {
            'Authorization': `Bearer ${state.token}`,
          },
        });
        const data = await response.json();
        state.giftCards = data || [];
      } catch (error) {
        console.error('Error fetching gift cards:', error);
      }
    };

    const submitChangeRequest = () => {
      if (state.selectedGiftCardIds.length > 0) {
        console.log('Selected gift card IDs:', state.selectedGiftCardIds);
        console.log('Business ID:', JSON.parse(localStorage.getItem('user')).business_id);
        console.log('Change request details:', changeRequestDetails.value);
        // Add your logic to handle the change request here
        showChangeRequestModal.value = false;
      }
    };

    const toggleCodes = (giftCardId) => {
      state.showCodes = {
        ...state.showCodes,
        [giftCardId]: !state.showCodes[giftCardId],
      };
    };

    const formatCode = (code) => {
      return code.replace(/(.{4})/g, '$1-').slice(0, -1);
    };

    const toggleSelectAll = (event) => {
      if (event.target.checked) {
        state.selectedGiftCardIds = state.giftCards.map(giftCard => giftCard._id);
      } else {
        state.selectedGiftCardIds = [];
      }
      updateRequestChangeButton();
    };

    const updateRequestChangeButton = () => {
      isRequestChangeEnabled.value = state.selectedGiftCardIds.length > 0;
    };

    const isAllSelected = computed(() => {
      return state.giftCards.length > 0 && state.selectedGiftCardIds.length === state.giftCards.length;
    });

    const giftCardsWithShownCodes = computed(() => {
      return state.giftCards.filter(giftCard => state.showCodes[giftCard._id]);
    });

    const isRequestChangeEnabled = computed(() => {
      return state.selectedGiftCardIds.length > 0;
    });

    const exportToCSV = () => {
  const selectedGiftCards = state.giftCards.filter(giftCard => 
    state.selectedGiftCardIds.includes(giftCard._id)
  );
  
  const csvContent = [
    ['Deal Title', 'Category', 'Option', 'Price', 'Discount', 'Max Per Purchase', 'In Stock', 'Gift Card Codes'],
    ...selectedGiftCards.flatMap(giftCard => {
      if (hasOptions(giftCard)) {
        // Handle multiple options
        return Array.from({ length: getOptionsLength(giftCard) }, (_, index) => {
          const codes = giftCard.giftcardcodes[index] || [];
          return [
            giftCard.dealtitle,
            giftCard.category,
            `Option ${index + 1}`,
            `$${Array.isArray(giftCard.currentprice) ? giftCard.currentprice[index] : giftCard.currentprice}`,
            `${Array.isArray(giftCard.discount) ? giftCard.discount[index] : giftCard.discount}%`,
            Array.isArray(giftCard.maxperpurchase) ? giftCard.maxperpurchase[index] : giftCard.maxperpurchase,
            Array.isArray(giftCard.numberinstock) ? giftCard.numberinstock[index] : giftCard.numberinstock,
            codes.length > 0 ? codes.map(formatCode).join('; ') : 'No codes available'
          ];
        });
      } else {
        // Handle single option
        return [[
          giftCard.dealtitle,
          giftCard.category,
          'Single Option',
          `$${giftCard.currentprice}`,
          `${giftCard.discount}%`,
          giftCard.maxperpurchase,
          giftCard.numberinstock,
          giftCard.giftcardcodes[0]?.length > 0 
            ? giftCard.giftcardcodes[0].map(formatCode).join('; ') 
            : 'No codes available'
        ]];
      }
    })
  ].map(row => 
    row.map(cell => 
      // Escape special characters and wrap in quotes if needed
      typeof cell === 'string' && (cell.includes(',') || cell.includes('"') || cell.includes('\n'))
        ? `"${cell.replace(/"/g, '""')}"`
        : cell
    ).join(",")
  ).join("\n");

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', 'gift_cards.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

    onMounted(() => {
      fetchGiftCards();
    });

    return {
      state,
      giftCardsWithShownCodes,
      showChangeRequestModal,
      changeRequestDetails,
      submitChangeRequest,
      toggleCodes,
      formatCode,
      toggleSelectAll,
      updateRequestChangeButton,
      isAllSelected,
      isRequestChangeEnabled,
      exportToCSV,
      hasOptions,
      getOptionsLength,
    };
  },
};
</script>

<style scoped>
.dashboard-content {
  margin-left: 300px;
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
}

.dashboard-content h1 {
  margin-top: 0;
}

.giftCards-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.giftCards-table-container {
  overflow-y: auto;
  max-height: 400px;
}

.giftCards-table {
  width: 100%;
  border-collapse: collapse;
}

.giftCards-table th,
.giftCards-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.giftCards-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.giftCards-table td {
  vertical-align: top;
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 15px;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.giftCodes-container {
  margin-top: 20px;
}

.giftCodes-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.giftCodes-table th,
.giftCodes-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.giftCodes-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 500px;
  max-width: 90%;
}

.description-box {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* Custom checkbox styles */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #4C6B30;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type="checkbox"]:checked {
  background-color: #4C6B30;
}

input[type="checkbox"]:checked::after {
  content: '\2714';
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"]:hover {
  border-color: #6E9F49;
}

input[type="checkbox"]:checked:hover {
  background-color: #6E9F49;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 0px; /* Adjust margin for smaller screens */
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 200px; /* Adjust margin for smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
    margin-top: 50px;
  }

  .giftCards-header {
    justify-content: center; /* Center header on smaller screens */
    margin-bottom: 8px; /* Adjust margin for smaller screens */
  }

  button {
    padding: 8px 16px; /* Adjust button padding for smaller screens */
    margin-bottom: 25px;
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    margin-left: 0; /* Remove margin for very small screens */
    padding: 10px; /* Adjust padding for very small screens */
    margin-top: 50px;
    width: 100%
  }

  .giftCards-header {
    flex-direction: column; /* Stack header items vertically */
    align-items: center; /* Center header items */
    margin-bottom: 6px; /* Adjust margin for very small screens */
  }

  .giftCards-header button {
    align-self: flex-start; /* Move button to the left */
  }

  .giftCards-table-container {
    max-height: 600px; /* Increase table height */
  }

  button {
    padding: 6px 12px; /* Adjust button padding for very small screens */
  }
}
</style>