  <template>
    <div class="dashboard-container">
      <MetaManager
        title="Admin - Payouts"
        description="Payouts to local businesses"
      />
      <h1>Payouts</h1>
      <div class="date-range">
        <label for="dateRange">Change Payout Dates:</label>
        <input type="date" id="startDate" v-model="startDate" @change="updateDateRange">
        <input type="date" id="endDate" v-model="endDate" @change="updateDateRange">
        <p>Current dates: {{ dateRange }}</p>
      </div>
      <button @click="exportToExcel" class="export-button">Export to Excel</button>
      <br>
      <small>*Make sure all businesses are loaded before exporting</small>
      <table>
        <thead>
          <tr>
            <th>Business Name</th>
            <th>Payout Amount (Current Month)</th>
            <th>Payout Requested</th>
            <th>Trio Tech Revenue</th>
            <th>Business Rep</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="business in displayedBusinesses" :key="business.business_name">
            <td>{{ business.business_name }}</td>
            <td>{{ business.current_month_payout.toFixed(2) }}</td>
            <td>{{ business.requested_payout ? 'Yes' : 'No' }}</td>
            <td> {{ business.trio_tech_cut.toFixed(2) }}</td>
            <td>{{ business.rep ? business.rep  : 'Trio Tech' }}</td>
            <td>
              <button 
                v-if="business.requested_payout" 
                @click="markAsPaid(business.business_id)" 
                class="paid-button"
              >
                Paid
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="load-more-container" v-if="hasMoreBusinesses">
        <button @click="loadMore" class="load-more-button">Load More</button>
      </div>
    </div>
  </template>

  <script>
  import axios from 'axios';
  import MetaManager from '../../components/MetaManager.vue';

  export default {
    name: 'DashboardAdminPayout',
    components: {
      MetaManager,
    },
    data() {
      return {
        businesses: [],
        startDate: '',
        endDate: '',
        dateRange: '',
        currentPage: 1,
        businessesPerPage: 30
      };
    },
    computed: {
      displayedBusinesses() {
        return this.businesses.slice(0, this.currentPage * this.businessesPerPage);
      },
      hasMoreBusinesses() {
        return this.displayedBusinesses.length < this.businesses.length;
      }
    },
    mounted() {
      this.fetchPayoutData();
      this.calculateDateRange();
    },
    methods: {
      async fetchPayoutData(startDate = this.startDate, endDate = this.endDate) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/business-sales/payouts`, {
            params: { startDate, endDate },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          this.businesses = response.data;
        } catch (error) {
          console.error('Error fetching payout data:', error);
        }
      },
      calculateDateRange() {
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split('T')[0];
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];
        this.startDate = firstDay;
        this.endDate = lastDay;
        this.dateRange = `${firstDay} - ${lastDay}`;
      },
      updateDateRange() {
        if (this.startDate && this.endDate) {
          this.dateRange = `${this.startDate} - ${this.endDate}`;
          this.fetchPayoutData(this.startDate, this.endDate);
        }
      },
      loadMore() {
        this.currentPage++;
      },
      async markAsPaid(businessId) {
        try {
          await axios.post(
            `${process.env.VUE_APP_API_URL}/api/business-sales/reset-payout`,
            { business_id: businessId },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
          // Update the requested_payout status
          const business = this.businesses.find(b => b.business_id === businessId);
          if (business) {
            business.requested_payout = false;
          }
        } catch (error) {
          console.error('Error marking payout as paid:', error);
        }
      },
      exportToExcel() {
      const headers = ['Business Name', 'Payout Amount (Current Month)', 'Payout Requested', 'Trio Tech Revenue', 'Business Rep'];
      const rows = this.displayedBusinesses.map(business => [
        business.business_name,
        business.current_month_payout.toFixed(2),
        business.requested_payout ? 'Yes' : 'No',
        business.trio_tech_cut.toFixed(2),
        business.rep || 'Trio Tech',
      ]);

      const csvContent = [headers, ...rows]
        .map(e => e.join(','))
        .join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Payouts_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    },
  };
  </script>

  <style scoped>
  .dashboard-container {
    margin-left: 300px; /* Adjust for the sidebar */
    padding: 20px;
  }

  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .dashboard-container {
      margin-left: 0; /* Remove margin for sidebar */
      padding: 15px; /* Adjust padding for medium screens */
    }
  }

  @media (max-width: 768px) {
    .dashboard-container {
      padding: 10px; /* Adjust padding for small screens */
    }
  }

  @media (max-width: 480px) {
    .dashboard-container {
      padding: 5px; /* Adjust padding for extra small screens */
    }
  }

  .date-range {
    margin-bottom: 20px;
    font-size: 1.2em;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f2f2f2;
  }

  tbody tr:hover {
    background-color: #f1f1f1;
  }

  .load-more-container {
    text-align: center;
    margin-top: 20px;
  }

  .load-more-button {
    padding: 10px 20px;
    background-color: #4C6B30; /* Dark Green */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }

  .load-more-button:hover {
    background-color: #6E9F49; /* Accent Green */
  }

  .paid-button {
    padding: 5px 10px;
    background-color: #4C6B30;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
  }

  .paid-button:hover {
    background-color: #6E9F49;
  }
  
  .export-button {
  padding: 10px 20px;
  margin-bottom: 15px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.export-button:hover {
  background-color: #6E9F49;
}
  </style>