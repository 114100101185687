<template>
  <div class="dashboard-content">
    <MetaManager
        title="Admin - Update User"
        description="Update users on the site"
      />
    <div class="form-container">
      <h1>Update User</h1>
      <div class="form-group">
        <input
          type="text"
          v-model="email"
          placeholder="Enter user email"
          @input="searchUser"
          class="input-field"
        />
        <div v-if="userSuggestions.length > 0" class="suggestions">
          <ul>
            <li
              v-for="user in userSuggestions"
              :key="user.id"
              @click="selectUser(user)"
            >
              {{ user.email }}
            </li>
          </ul>
        </div>
      </div>
      <div v-if="user">
        <form @submit.prevent="updateUser">
          <div class="form-group-inline">
            <label>ID:</label>
            <span class="form-value">{{ user.id }}</span>
          </div>
          <div class="form-group">
            <label>Full Name:</label>
            <input type="text" v-model="user.full_name" class="input-field" />
          </div>
          <div class="form-group">
            <label>Email:</label>
            <input type="text" v-model="user.email" class="input-field" />
          </div>
          <div class="form-group-inline">
            <label>Is Admin:</label>
            <input type="checkbox" v-model="user.is_admin" class="checkbox-field" />
          </div>
          <div class="form-group-inline">
            <label>Is Business:</label>
            <input
              type="checkbox"
              v-model="user.is_business"
              @change="checkBusinessStatus"
              class="checkbox-field"
            />
          </div>
          <div class="form-group" v-if="user.is_business">
            <label>Business ID:</label>
            <input type="text" v-model="user.business_id" readonly class="input-field" />
          </div>
          <div class="form-group" v-if="user.is_business">
            <input
              type="text"
              v-model="businessName"
              placeholder="Search for business"
              @input="searchBusiness"
              class="input-field"
            />
            <div v-if="businessSuggestions.length > 0" class="suggestions">
              <ul>
                <li
                  v-for="business in businessSuggestions"
                  :key="business.id"
                  @click="selectBusiness(business)"
                >
                  {{ business.business_name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="form-buttons">
            <button type="submit" class="submit-button">Update User</button>
            <button type="button" @click="cancelUpdate" class="cancel-button">Cancel</button>
          </div>
        </form>
      </div>
      <div v-if="isLoading" class="loading-overlay">
        <div class="loader"></div>
        <p>Updating the user, please wait...</p>
      </div>
      <div v-if="isUpdated" class="updated-overlay">
        <p>User Updated Successfully</p>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import MetaManager from '../../components/MetaManager.vue';

export default {
  name: 'DashboardUpdateUser',
  components: {
    MetaManager,
  },
  data() {
    return {
      email: '',
      user: null,
      userSuggestions: [],
      businessName: '',
      businessSuggestions: [],
      isLoading: false, // State to control the loading spinner visibility
      isUpdated: false, // State to control the "User Updated" message visibility
    };
  },
  async created() {
    // Check if email is passed as a query parameter
    const emailFromQuery = this.$route.query.email;
    if (emailFromQuery) {
      this.email = emailFromQuery;
      await this.fetchUserByEmail(emailFromQuery);
    }
  },
  methods: {
    async fetchUserByEmail(email) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/updates/search-user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          params: { email },
        });

        if (response.data.length > 0) {
          this.user = response.data[0];
        }
      } catch (error) {
        console.error('Error fetching user by email:', error);
      }
    },
    async searchUser() {
      if (this.email.length > 2) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/updates/search-user`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            params: {
              email: this.email,
            },
          });
          this.userSuggestions = response.data;
        } catch (error) {
          console.error('Error fetching user:', error);
        }
      } else {
        this.userSuggestions = [];
      }
    },
    selectUser(user) {
      this.user = user;
      this.email = user.email;
      this.userSuggestions = [];
    },
    async searchBusiness() {
      if (this.businessName.length > 2) {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/updates/search-business`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            params: {
              name: this.businessName,
            },
          });
          this.businessSuggestions = response.data;
        } catch (error) {
          console.error('Error fetching business:', error);
        }
      } else {
        this.businessSuggestions = [];
      }
    },
    selectBusiness(business) {
      this.user.business_id = business._id;
      this.businessName = business.business_name;
      this.businessSuggestions = [];
    },
    checkBusinessStatus() {
      if (!this.user.is_business) {
        this.user.business_id = '';
      }
    },
    async updateUser() {
      this.isLoading = true; // Show the loading spinner
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/updates/update-user`, {
          id: this.user.id,
          full_name: this.user.full_name,
          email: this.user.email,
          is_admin: this.user.is_admin,
          is_business: this.user.is_business,
          business_id: this.user.business_id || null,
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }});

        // Simulate delay for loading spinner visibility
        setTimeout(() => {
          this.isLoading = false; // Hide the spinner
          this.isUpdated = true; // Show the "User Updated" message

          // Hide the "User Updated" message after a few seconds
          setTimeout(() => {
            this.isUpdated = false;
            location.reload();
          }, 3000); // Adjust this value to control how long the message is shown
        }, 2000); // Adjust this delay as needed
      } catch (error) {
        console.error('Error updating user:', error);
        this.isLoading = false; // Ensure spinner is hidden in case of error
      }
    },
    cancelUpdate() {
      this.clearForm();
    },
    clearForm() {
      this.email = '';
      this.user = null;
      this.userSuggestions = [];
      this.businessName = '';
      this.businessSuggestions = [];
    }
  },
};
</script>

<style scoped>
.dashboard-content {
  padding: 20px;
  margin-left: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 40px);
  box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 0; /* Remove margin for sidebar */
    padding: 15px; /* Adjust padding for medium screens */
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    padding: 10px; /* Adjust padding for small screens */
    flex-direction: column; /* Stack elements vertically */
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    padding: 5px; /* Adjust padding for extra small screens */
    max-height: 30vh;
  }
  .form-container{
    margin-top: 350px;
  }
}

.form-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  position: relative;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group,
.form-group-inline {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  position: relative;
}

.form-group-inline {
  flex-direction: row;
  align-items: center;
}

.form-group-inline label {
  width: 100px;
  margin-bottom: 0;
}

.form-group-inline .form-value {
  margin-left: 10px;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group label {
  margin-right: 10px;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
}

.input-field {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.checkbox-field {
  margin-left: 10px;
}

.form-value {
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.submit-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 48%;
}

.submit-button {
  background-color: #4C6B30;
  color: white;
}

.submit-button:hover {
  background-color: #6E9F49;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

ul {
  background-color: #fff;
  border: 1px solid #ddd;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
}

li {
  padding: 10px;
  cursor: pointer;
}

li:hover {
  background-color: #f2f2f2;
}

.suggestions {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  z-index: 1000;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4C6B30;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

.updated-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.updated-overlay p {
  font-size: 18px;
  color: #333;
}

p {
  font-size: 18px;
  color: #333;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
