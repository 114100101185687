<template>
    <div>
<!-- This component doesn't render anything -->
    </div>
</template>

<script>
export default {
  name: 'MetaManager',
  props: {
    title: String,
    description: String
  },
  data() {
    return {
      defaultTitle: this.getDefaultTitle()
    }
  },
  methods: {
    getDefaultTitle() {
      const hostname = window.location.hostname;
      return hostname.includes('summitshopper.com') ? 'Summit Shopper Home' : 'Stark Shopper Home';
    },
    updateTitle(newTitle) {
      // Set flag to indicate Vue is managing the title
      window.titleSetByVue = true;
      
      // If a specific title is provided, use it, otherwise use the domain-specific default
      document.title = newTitle || this.defaultTitle;
    },
    updateDescription(newDescription) {
      let meta = document.querySelector('meta[name="description"]');
      if (!meta) {
        meta = document.createElement('meta');
        meta.name = 'description';
        document.head.appendChild(meta);
      }
      meta.content = newDescription || 'Stark Shopper offers discounted gift cards with amazing incentives. Save more on your favorite brands and enjoy exclusive deals.';
    }
  },
  watch: {
    title: {
      immediate: true,
      handler(newTitle) {
        this.updateTitle(newTitle);
      }
    },
    description: {
      immediate: true,
      handler(newDescription) {
        this.updateDescription(newDescription);
      }
    }
  },
  mounted() {
    // Ensure the correct title is set when the component mounts
    this.updateTitle(this.title);
    this.updateDescription(this.description);
  },
  beforeUnmount() {
    // Reset to default domain-specific title when component is destroyed
    document.title = this.defaultTitle;
    window.titleSetByVue = false;
  }
}
</script>