<template>
  <div class="step-details">
    <h2>Step 1: Details</h2>
    <div class="form-group">
      <label for="dealTitle">Deal Title</label>
      <input type="text" id="dealTitle" maxlength="30" v-model="dealtitle" @input="saveAndValidateForm"  required style="text-transform: uppercase;" />
    </div>

    <div class="form-group">
      <label for="expirationDate">Expiration Date</label>
      <input
        type="date"
        id="expirationDate"
        v-model="expirationdateInput" 
        @input="handleDateInput"
        required
      />
    </div>

    <div class="form-group">
      <label for="couponCode">Incentive Code</label>
      <div class="coupon-code-container">
        <input type="text" id="couponCode" v-model="couponcode" @input="saveAndValidateForm" required />
        <button type="button" @click="generateCouponCode">Generate</button>
      </div>
    </div>

    <div class="form-group">
      <label for="dealDetails">About Deal</label>
      <div id="dealDetails" ref="dealDetailsEditor"></div>
    </div>

    <div class="form-group">
      <label for="dealFinePrint">Fine Print</label>
      <div id="dealFinePrint" ref="dealFinePrintEditor"></div>
    </div>
  </div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
  name: 'StepDetails',
  data() {
    return {
      dealtitle: '',
      expirationdate: '', // MM-DD-YYYY for saving
      expirationdateInput: '', // YYYY-MM-DD for the date input
      couponcode: '',
      dealdetails: '',
      dealfineprint: '',
      quill: null,
      quillFinePrint: null,
    };
  },
  methods: {

    calculateDaysDifference(selectedDate) {
      // Create dates using YYYY-MM-DD format to avoid timezone issues
      const today = new Date().toISOString().split('T')[0];
      const target = new Date(selectedDate).toISOString().split('T')[0];
      
      // Create new Date objects using the YYYY-MM-DD strings
      const todayObj = new Date(today + 'T00:00:00');
      const targetObj = new Date(target + 'T00:00:00');
      
      const diffTime = targetObj - todayObj;
      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
      
      return diffDays;
    },
    // Handle date input in the date picker (YYYY-MM-DD format) and save it as MM-DD-YYYY in localStorage
    handleDateInput() {
      if (this.expirationdateInput) {
        // Calculate days difference and store it
        const daysDiff = this.calculateDaysDifference(this.expirationdateInput);
        this.expirationdate = daysDiff.toString();
      } else {
        this.expirationdate = '';
      }
      this.saveAndValidateForm();
    },

    // Convert the saved MM-DD-YYYY date to YYYY-MM-DD for the input field
    loadDateForInput() {
      if (this.expirationdate) {
        // Convert days difference back to a date
        const date = new Date();
        date.setDate(date.getDate() + parseInt(this.expirationdate));
        this.expirationdateInput = date.toISOString().split('T')[0];
      }
    },

    // Save and validate form data
    saveAndValidateForm() {
      this.saveDetails();
      this.validateForm();
    },

    // Validate form fields and trigger validation
    validateForm() {
      const isValid =
        this.dealtitle.trim().length > 0 &&
        this.expirationdate.trim().length > 0 &&
        this.quill.root.innerHTML.trim().length > 0 &&
        this.quillFinePrint.root.innerHTML.trim().length > 0;

      this.$emit('formValidation', isValid);
    },

    // Save form data to localStorage
    saveDetails() {
      const dealDetails = {
        dealtitle: this.dealtitle.toUpperCase(),
        expirationdate: this.expirationdate, // Store the number of days
        expirationdateInput: this.expirationdateInput, // Store the actual date for reference
        couponcode: this.couponcode,
        dealdetails: this.quill.root.innerHTML,
        dealfineprint: this.quillFinePrint.root.innerHTML,
      };
      localStorage.setItem('editDetails', JSON.stringify(dealDetails));
    },
    // Load details from localStorage
    loadDetails() {
      const savedDetails = JSON.parse(localStorage.getItem('editDetails'));
      if (savedDetails) {
        this.dealtitle = savedDetails.dealtitle || '';
        this.expirationdate = savedDetails.expirationdate || '';
        this.couponcode = savedDetails.couponcode || '';
        this.dealdetails = savedDetails.dealdetails || '';
        this.dealfineprint = savedDetails.dealfineprint || '';

        // If we have a saved expirationdateInput, use it directly
        if (savedDetails.expirationdateInput) {
          this.expirationdateInput = savedDetails.expirationdateInput;
        } else {
          // Otherwise, calculate the date from days difference
          this.loadDateForInput();
        }

        if (this.quill) {
          this.quill.root.innerHTML = this.dealdetails;
        }
        if (this.quillFinePrint) {
          this.quillFinePrint.root.innerHTML = this.dealfineprint;
        }
      }
    },
    // Generate a random coupon code
    generateCouponCode() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let couponCode = '';
      for (let i = 0; i < 12; i++) {
        if (i > 0 && i % 3 === 0) {
          couponCode += '-';
        }
        couponCode += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      this.couponcode = couponCode;
      this.saveAndValidateForm();
    },
  },

  mounted() {
    // Initialize Quill editor for Deal Details
    this.quill = new Quill(this.$refs.dealDetailsEditor, {
      theme: 'snow',
      placeholder: 'Describe the deal...',
      modules: {
        toolbar: [
          [{ 'font': [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }],
          [{ 'align': [] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    // Initialize Quill editor for Deal Fine Print
    this.quillFinePrint = new Quill(this.$refs.dealFinePrintEditor, {
      theme: 'snow',
      placeholder: 'Add fine print...',
      modules: {
        toolbar: [
          [{ 'font': [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }],
          [{ 'align': [] }],
          ['bold', 'italic', 'underline'],
          ['link', 'blockquote', 'image'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    });

    // Load details from localStorage if they exist
    this.loadDetails();

    // Set up event listeners for Quill editors
    this.quill.on('text-change', () => {
      this.dealdetails = this.quill.root.innerHTML;
      this.saveAndValidateForm();
    });

    this.quillFinePrint.on('text-change', () => {
      this.dealfineprint = this.quillFinePrint.root.innerHTML;
      this.saveAndValidateForm();
    });

    // Validate form on page load
    this.$emit(
      'formValidation',
      this.dealtitle && this.expirationdate && this.couponcode && this.dealdetails && this.dealfineprint
    );
  },

  created() {
    // Load details whenever the component is created (on page reload or when returning)
    this.loadDetails();
  },
};
</script>


<style scoped>
.step-details {
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

#dealDetails,
#dealFinePrint {
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

#dealDetails:focus,
#dealFinePrint:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

.coupon-code-container {
  display: flex;
  gap: 10px;
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .form-group {
    margin-bottom: 15px;
  }

  input,
  select,
  textarea,
  #dealDetails,
  #dealFinePrint {
    padding: 8px;
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .form-group {
    margin-bottom: 10px;
  }

  input,
  select,
  textarea,
  #dealDetails,
  #dealFinePrint {
    padding: 6px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .form-group {
    margin-bottom: 8px;
  }

  input,
  select,
  textarea,
  #dealDetails,
  #dealFinePrint {
    padding: 5px;
    font-size: 13px;
  }
}
</style>
