<template>
  <div class="dashboard-page">
    <MetaManager
        title="Admin - Create Gift Card"
        description="Create gift cards for businesses"
      />
    <div class="dashboard-content" :class="{ 'loading': isLoading }">
      <h1>Create Gift Card</h1>
      <div class="steps-container">
        <StepIndicator :giftCardStep="giftCardStep" />
        <component
          ref="currentStepComponent"
          :is="currentStepComponent"
          @nextStep="nextStep"
          @formValidation="updateFormValidation"
          :giftCardStep="giftCardStep"
        />
        <div class="navigation-buttons">
          <button @click="prevStep" :disabled="giftCardStep === 0">Previous</button>
          <button @click="cancel">Cancel</button>
          <button @click="handleNextStep" :disabled="!isFormValid">{{ giftCardStep === steps.length - 1 ? 'Publish' : 'Next' }}</button>
        </div>
      </div>
      <div v-if="showPublishConfirm" class="publish-confirmation">
        <p>Are you sure you want to publish?</p>
        <button @click="publish">Yes</button>
        <button @click="cancelPublish">No</button>
      </div>
      <div v-if="isLoading" class="loading-overlay">
        <div class="loader"></div>
        <p>Saving, do not exit or refresh this screen...</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import StepIndicator from '../../components/Dashboard/create giftcard/StepIndicator.vue';
import StepDetails from '../../components/Dashboard/create giftcard/StepDetails.vue';
import StepOptions from '../../components/Dashboard/create giftcard/StepOptions.vue';
import StepImages from '../../components/Dashboard/create giftcard/StepImages.vue';
import StepPreview from '../../components/Dashboard/create giftcard/StepPreview.vue';
import MetaManager from '../../components/MetaManager.vue';

export default {
  name: 'CreateGiftCard',
  components: {
    StepIndicator,
    StepDetails,
    StepOptions,
    StepImages,
    StepPreview,
    MetaManager,
  },
  data() {
    return {
      giftCardStep: 0,
      steps: ['StepDetails', 'StepOptions', 'StepImages', 'StepPreview'],
      isFormValid: false,
      showPublishConfirm: false, // New state for publish confirmation pop-up
      isLoading: false, // New state for loading overlay
      token: localStorage.getItem('token'),
    };
  },
  computed: {
    currentStepComponent() {
      return this.steps[this.giftCardStep];
    },
  },
  methods: {
    updateFormValidation(isValid) {
      this.isFormValid = isValid;
    },
    async handleNextStep() {
      const currentComponent = this.$refs.currentStepComponent;

      if (this.isFormValid) {
        if (this.giftCardStep === 0 && currentComponent.validateAndSave) {
          currentComponent.validateAndSave();
        } else if (this.giftCardStep === 1 && currentComponent.handleNextStep) {
          currentComponent.handleNextStep();
        } else if (this.giftCardStep === 2 && currentComponent.saveImages) {
          currentComponent.saveImages();
          this.nextStep();
        } else if (this.giftCardStep === this.steps.length - 1) {
          this.showPublishConfirm = true; // Show the publish confirmation pop-up
        } else {
          this.nextStep();
        }
      }
    },
    nextStep() {
      if (this.giftCardStep < this.steps.length - 1) {
        this.giftCardStep++;
        localStorage.setItem('giftCardStep', this.giftCardStep);
      }
    },
    prevStep() {
      if (this.giftCardStep > 0) {
        this.giftCardStep--;
        localStorage.setItem('giftCardStep', this.giftCardStep);
      }
    },
    async publish() {
      this.isLoading = true;

      const dealDetails = JSON.parse(localStorage.getItem('giftCardDetails')) || {};
      const uploadedImages = JSON.parse(localStorage.getItem('uploadedImagesGC')) || [];
      const options = JSON.parse(localStorage.getItem('savedOptions')) || [];
      const currentBusiness = JSON.parse(localStorage.getItem('currentBusiness'));

      if (!dealDetails || !currentBusiness) {
        console.error('Missing deal details or current business data');
        this.isLoading = false;
        return;
      }

      const formData = new FormData();
      formData.append('dealDetails', dealDetails.dealDetails || '');
      formData.append('dealTitle', dealDetails.dealTitle || '');
      formData.append('dealFinePrint', dealDetails.dealFinePrint || '');
      formData.append('istrending', 0);
      formData.append('ispublished', 1);
      formData.append('business_id', currentBusiness);

      if (uploadedImages.length > 0) {
        uploadedImages.forEach((image) => {
          const blob = this.dataURLtoBlob(image.url);
          formData.append('giftCard_image', blob, image.name);
        });
      } else {
        console.warn('No uploaded images found.');
      }

      formData.append('options', JSON.stringify(options));

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/create-giftCard`, formData, {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('API response:', response.data);

        // Clear localStorage and navigate to the dashboard
        localStorage.removeItem('giftCardDetails');
        localStorage.removeItem('uploadedImagesGC');
        localStorage.removeItem('savedOptions');
        localStorage.removeItem('currentBusiness');
        localStorage.setItem('giftCardStep', 0);
        this.$router.push('/dashboard');
      } catch (error) {
        console.error('Error publishing gift card:', error);
        this.isLoading = false; // Hide loading overlay in case of error
      }
    },
    cancelPublish() {
      this.showPublishConfirm = false; // Hide the publish confirmation pop-up
    },
    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    cancel() {
      localStorage.removeItem('giftCardDetails');
      localStorage.removeItem('currentBusiness');
      localStorage.removeItem('uploadedImages');
      localStorage.removeItem('options');
      localStorage.removeItem('savedOptions')
      localStorage.setItem('codesGenerated', false);
      localStorage.setItem('giftCardStep', 0);
      this.giftCardStep = 0;
      location.reload(); // Refresh the page
    },
  },
  mounted() {
    const savedStep = localStorage.getItem('giftCardStep');
    if (savedStep !== null) {
      this.giftCardStep = parseInt(savedStep, 10);
    }
  },
};
</script>

<style scoped>
.dashboard-content {
  margin-left: 300px;
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  width: 100%;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.publish-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.publish-confirmation p {
  margin-bottom: 10px;
}

.publish-confirmation button {
  margin-right: 10px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4C6B30;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dashboard-content.loading::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 0px;
    margin-top: 100px;
    padding: 15px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .navigation-buttons button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 100px;
    padding: 10px;
    margin-top: 50px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .navigation-buttons button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    margin-left: 0;
    padding: 5px;
    margin-top: 80px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .navigation-buttons button {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>
