<template>
    <div class="gift-card-sales-summary">
        <h2>Gift Card Sales Summary</h2>
        <div class="table-responsive">
            <table>
                <thead>
                    <tr>
                        <th>Gift Card Title</th>
                        <th>Amount Sold</th>
                        <th>Total Revenue</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="giftCard in summarizedSales" :key="giftCard.dealTitle">
                        <td class="deal-title">{{ giftCard.dealTitle }}</td>
                        <td>{{ giftCard.unitsSold }}</td>
                        <td>${{ giftCard.totalRevenue.toFixed(2) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GiftCardSalesSummary',
    props: {
        orders: {
            type: Array,
            required: true,
        },
    },
    computed: {
        summarizedSales() {
            const salesSummary = {};

            // Loop through all orders and items to count sales per gift card title
            this.orders.forEach(order => {
                order.items.forEach(item => {
                    if (!salesSummary[item.dealTitle]) {
                        salesSummary[item.dealTitle] = {
                            dealTitle: item.dealTitle,
                            unitsSold: 0,
                            totalRevenue: 0,
                        };
                    }

                    // Increment units sold
                    salesSummary[item.dealTitle].unitsSold += item.quantity;

                    // Increment total revenue
                    const totalCost = parseFloat(item.totalCost);
                    salesSummary[item.dealTitle].totalRevenue += isNaN(totalCost) ? 0 : totalCost * item.quantity;
                });
            });

            // Convert the object to an array for easier rendering
            return Object.values(salesSummary).map(sale => ({
                ...sale,
                totalRevenue: isNaN(sale.totalRevenue) ? 0 : sale.totalRevenue,
                unitsSold: isNaN(sale.unitsSold) ? 0 : sale.unitsSold,
            }));
        },
    },
};
</script>

<style scoped>
.gift-card-sales-summary {
    font-family: Arial, sans-serif;
    margin-left: 20px;
}

.table-responsive {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table, th, td {
    border: 1px solid #e0e0e0;
}

th, td {
    padding: 10px;
    text-align: left;
}

th {
    background-color: #f0f0f0;
}

.deal-title {
    max-width: 75ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>