<template>
    <Transition name="fade" appear>
      <div v-if="isVisible" class="popup-overlay">
        <Transition name="popup" appear>
          <div v-if="isVisible" class="popup-container">
            <button class="close-button" @click="closePopup">&times;</button>
            <div class="popup-header">
              <img class="popup-logo" src="@/assets/logo.png" alt="Stark Shopper Logo" />
            </div>
            <div class="popup-content">
              <p class="main-text">
                Thank you for visiting StarkShopper.com.
              </p>
              <p class="info-text">
                As we just kickoff, expect to see NEW Gift Card offers AND NEW Incentives (Coupons) often.
              </p>
              <p class="info-text">
                Over a short period of time, we will have HUNDREDS of Great Businesses for you to save at.
              </p>
              <p class="final-text">
                Check back often!
              </p>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  
  export default {
    setup() {
      const isVisible = ref(!localStorage.getItem('checkBackPopUp'));
  
      const closePopup = () => {
        isVisible.value = false;
        localStorage.setItem('checkBackPopUp', 'true');
      };
  
      // Check if component is mounted on client-side before checking localStorage
      onMounted(() => {
        if (localStorage.getItem('checkBackPopUp')) {
          isVisible.value = false;
        }
      });
  
      return {
        isVisible,
        closePopup,
      };
    },
  };
  </script>
  
  <style scoped>
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  
  .popup-container {
    position: relative;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 15px;
    width: 80%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
    color: #333;
  }
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    color: #666;
    cursor: pointer;
    padding: 5px 10px;
    transition: color 0.3s ease;
  }
  
  .close-button:hover {
    color: #333;
  }
  
  .popup-header {
    margin-bottom: 20px;
  }
  
  .popup-logo {
    width: 100px;
    margin: auto;
  }
  
  .popup-content {
    padding: 0 20px;
  }
  
  .main-text {
    font-size: 1.2rem;
    margin-bottom: 25px;
    color: #2c3e50;
  }
  
  .info-text {
    font-size: 1.1rem;
    margin-bottom: 20px;
    color: #555;
    line-height: 1.5;
  }
  
  .final-text {
    font-size: 1.2rem;
    margin-top: 25px;
    color: #2c3e50;
    font-weight: bold;
  }
  
  /* Animation styles */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
  .popup-enter-active,
  .popup-leave-active {
    transition: all 0.3s ease;
  }
  
  .popup-enter-from,
  .popup-leave-to {
    transform: scale(0.9);
    opacity: 0;
  }
  
  @media (max-width: 768px) {
    .popup-container {
      width: 90%;
      padding: 20px;
    }
  
    .popup-content {
      padding: 0 10px;
    }
  
    .main-text,
    .info-text,
    .final-text {
      font-size: 1rem;
    }
  }</style>